import { useEffect, useRef, useState } from "react";
import { signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, Providers } from "../../config/firebase";
import { Box, Button, TextField, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import Center from "../utils/Center";
import { toast, ToastContainer } from "react-toastify";

interface Props {}

const AuthContainer = (props: Props) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const isElectron =
    !window.location.href.includes("stawro.pl") &&
    !window.location.href.includes("localhost");

  const useKeypress = (key: string, action: () => void) => {
    useEffect(() => {
      //@ts-ignore
      const onKeyup = (e) => {
        if (e.key === key) {
          action();
        }
      };
      window.addEventListener("keyup", onKeyup);

      return () => window.removeEventListener("keyup", onKeyup);
    }, [email, password]);
  };

  const buttonRef = useRef<HTMLButtonElement>(null);

  useKeypress("Enter", () => {
    if (buttonRef && buttonRef.current) {
      if (!email.trim().length || !password.trim().length) {
        toast.error("Uzupełnij pola email oraz hasło!");
        return;
      }

      buttonRef.current.click();
    }
  });

  function handleSubmit(event: any) {
    event.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast.error(errorMessage);
        console.error(`Error (${errorCode}):`, errorMessage);
      });
  }

  const signInWithGoogle = () => {
    setDisabled(true);
    signInWithPopup(auth, Providers.google)
      .then(() => {
        setDisabled(false);
        console.info("TODO: navigate to authenticated screen");
        navigate("/");
      })
      .catch((error) => {
        setErrorMessage(error.code + ": " + error.message);
        setDisabled(false);
      });
  };

  return (
    <Center height={"auto"}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Box width="550px" sx={{ display: "flex", flexDirection: "column" }}>
        {!isElectron && (
          <>
            <Button
              startIcon={<GoogleIcon />}
              size="large"
              disabled={disabled}
              variant="contained"
              onClick={signInWithGoogle}
            >
              Sign In With Google
            </Button>
            <Typography sx={{ mt: 2 }} color={"red"}>
              {errorMessage}
            </Typography>
            <Typography sx={{ fontStyle: "italic", textAlign: "center" }}>
              or
            </Typography>
          </>
        )}

        <Box
          display="grid"
          gap="10px"
          flexDirection="column"
          mt="10px"
          width="100%"
        >
          <TextField
            label="E-mail"
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Hasło"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="button"
            ref={buttonRef}
            onClick={handleSubmit}
            variant="contained"
            sx={{ height: "50px" }}
          >
            Sign in
          </Button>
        </Box>
      </Box>
    </Center>
  );
};

export default AuthContainer;
