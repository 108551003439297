import { createRef, useEffect, useMemo, useRef, useState } from "react";

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Center from "../components/utils/Center";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { EditItems } from "../components/settlements/EditItems";
import { auth, db } from "../config/firebase";
import { ItemType, SettlementItemType } from "./types";
import { collection, getDocs } from "@firebase/firestore";
import { isAdminUser, isAdminUserGranted } from "./helpers";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import AddAPhotoSharpIcon from "@mui/icons-material/AddAPhotoSharp";

import dayjs from "dayjs";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import * as htmlToImage from "html-to-image";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Typography } from "@mui/material";
import { EditItem } from "../components/inventory/EditItem";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import cloneDeep from "lodash.clonedeep";
import debounce from "lodash.debounce";

const RozliczeniaKomis = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [user] = useState(auth.currentUser);

  const [currentSelected, setCurrentSelected] = useState<SettlementItemType[]>(
    []
  );
  const [items, setItems] = useState<SettlementItemType[]>([]);

  const [itemsAll, setItemsAll] = useState<SettlementItemType[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsCollectionRef = collection(db, "items");
  const [showDeleted, setShowDeleted] = useState(false);
  const settlementsCollectionRef = collection(db, "settlements");
  const [currentSelectedItem, setCurrentSelectedItem] = useState<ItemType>();

  const [editModalOpen, setEditModalOpen] = useState(false);

  const editBlocked = !isAdminUser(user);
  const editBlockedPartially = !isAdminUserGranted(user);

  const [hideSettled, setHideSettled] = useState(true);

  const getItems = async () => {
    try {
      const data = await getDocs(settlementsCollectionRef);
      const items = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as SettlementItemType[];

      const deafultSortedItems = handleItemsOrder(items);

      setItems(deafultSortedItems);
      setItemsAll(deafultSortedItems);
      setCurrentSelected([]);
    } catch (error) {
      //@ts-ignore
      toast.error(error.message);
      console.error(error);
    }
  };

  const useKeypress = (key: string, action: () => void) => {
    useEffect(() => {
      //@ts-ignore
      const onKeyup = (e) => {
        if (e.key === key) {
          action();
        }
      };
      window.addEventListener("keyup", onKeyup);

      return () => window.removeEventListener("keyup", onKeyup);
    }, []);
  };

  useKeypress("Escape", () => {
    const noInputRefValue =
      !inputRef || !inputRef.current || !inputRef.current.value.length;

    if (noInputRefValue) {
      return;
    }

    inputRef.current.value = "";
    setSearchTerm("");

    console.log("naciśnieto klawisz escape - czyszczę filtrowanie");
  });

  useEffect(() => {
    const b = cloneDeep(itemsAll);
    const u = b.filter(
      (e) =>
        e.productName &&
        e.productName.toLowerCase().includes(searchTerm.toLowerCase().trim())
    );

    setItems(u);
  }, [searchTerm]);

  useEffect(() => {
    getItems();
  }, []);

  const handleMulitSettlement = (item: SettlementItemType) => {
    const itemAdded = currentSelected.find(
      (currentSelected) => currentSelected.id === item.id
    );

    if (itemAdded) {
      const updatedCurrentSelected = currentSelected.filter(
        (e) => e.id !== item.id
      );
      setCurrentSelected(updatedCurrentSelected);
    } else {
      setCurrentSelected((prev) => [...prev, item]);
    }
  };

  const [screenshotDisabled, setScreenshotDisabled] = useState(false);
  const dateTimeValue = dayjs().format("DD-MM-YYYY-HH:mm");
  const storage = getStorage();
  const tableRef = createRef<HTMLElement | null>();
  const screenShotName = `rozliczenia_${dateTimeValue}`;
  const tableImageRef = ref(
    storage,
    `screenshots/rozliczenia/${screenShotName}`
  );

  const [sortedBy, setSortedBy] = useState("createdDate");
  const [direction, setDireciton] = useState<{
    [key: string]: string;
  }>({ createdDate: "asc" });

  const takeScreenShot = async (node: HTMLElement) => {
    const blob = await htmlToImage.toBlob(node);

    if (!blob) {
      setScreenshotDisabled(false);
      toast.error("Coś poszło nie tak!");
      return;
    }

    try {
      await uploadBytes(tableImageRef, blob, {
        contentType: "image/jpeg",
        customMetadata: { filename: screenShotName },
      });
      toast.success("Screenshot został zapisany!");
    } catch (error) {
      toast.error("Coś poszło nie tak!");
    } finally {
      setScreenshotDisabled(false);
    }
  };

  const handleOpenElem = async (id: string) => {
    const data = await getDocs(itemsCollectionRef);
    const items = data.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as ItemType[];
    const [element] = items.filter((e) => e.id === id);

    if (element) {
      setCurrentSelectedItem(element);
      setEditModalOpen(true);
    }
  };

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const itemId = searchParams.get("id");
      const data = await getDocs(itemsCollectionRef);
      const items = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      })) as ItemType[];
      const [selectedItem] = items.filter((e) => e.id === itemId);

      if (!selectedItem) {
        return;
      }

      setCurrentSelectedItem(selectedItem);
      setEditModalOpen(true);
    })();
  }, [items]);

  const handleItemsOrder = (items: SettlementItemType[]) => {
    const itemsToUpdate = cloneDeep(items);

    if (sortedBy === "createdDate") {
      const data = itemsToUpdate.sort((a, b) => {
        if (direction.createdDate === "asc") {
          return (
            new Date(b.createDate).getTime() - new Date(a.createDate).getTime()
          );
        } else {
          return (
            new Date(a.createDate).getTime() - new Date(b.createDate).getTime()
          );
        }
      });

      return data;
    }

    if (sortedBy === "settlementDate") {
      const data = itemsToUpdate.sort((a, b) => {
        if (!a.settlementDate && !b.settlementDate) {
          return 0;
        } else if (!a.settlementDate) {
          return 1;
        } else if (!b.settlementDate) {
          return -1;
        } else {
          if (direction.settlementDate === "asc") {
            return (
              new Date(b.settlementDate).getTime() -
              new Date(a.settlementDate).getTime()
            );
          } else {
            return (
              new Date(a.settlementDate).getTime() -
              new Date(b.settlementDate).getTime()
            );
          }
        }
      });

      return data;
    }

    if (sortedBy === "settlementId") {
      const data = itemsToUpdate.sort((a, b) => {
        if (!a.settlementId && !b.settlementId) {
          return 0;
        } else if (!a.settlementId) {
          return 1;
        } else if (!b.settlementId) {
          return -1;
        } else {
          if (direction.settlementId === "asc") {
            return (
              new Date(b.settlementDate).getTime() -
              new Date(a.settlementDate).getTime()
            );
          } else {
            return (
              new Date(a.settlementDate).getTime() -
              new Date(b.settlementDate).getTime()
            );
          }
        }
      });

      return data;
    }
    return itemsToUpdate;
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedResults = useMemo(() => {
    return debounce(setSearchTerm, 500);
  }, []);

  useEffect(() => {
    const deafultSortedItems = handleItemsOrder(items);
    setItems(deafultSortedItems);
  }, [direction, sortedBy]);

  let summaryWojtek = 0;
  const haveDeleted = items.filter((e) => e.removed);
  const haveSettled = items.filter(
    (e) =>
      e.settled &&
      e.status === "sprzedano" &&
      e.settlementStatus === "rozliczono"
  );

  return (
    <Container
      sx={{ px: "0px !important", maxWidth: "100% !important", width: "100%" }}
    >
      <EditItem
        currentSelected={currentSelectedItem}
        setEditModalOpen={setEditModalOpen}
        editModalOpen={editModalOpen}
        getItems={getItems}
        editBlocked
      />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Box
        sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}
      >
        {!editBlocked ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mr: "auto",
              ml: "10px",
              height: "55px",
              cursor: "pointer",
            }}
          >
            <Button
              variant="contained"
              disabled={screenshotDisabled}
              onClick={() => {
                if (screenshotDisabled) {
                  return;
                }

                if (tableRef && tableRef.current) {
                  setScreenshotDisabled(true);
                  takeScreenShot(tableRef.current);
                }
              }}
            >
              <AddAPhotoSharpIcon />
            </Button>
          </Box>
        ) : (
          <Box></Box>
        )}

        <Box
          sx={{ display: "flex", justifyContent: "space-between", ml: "auto" }}
        >
          <input
            style={{
              position: "relative",

              height: "55px",
              marginRight: "16px",
              borderRadius: "4px",
              padding: "4px 8px",
              boxSizing: "border-box",
              fontSize: "18px",
            }}
            ref={inputRef}
            type="text"
            placeholder="wyszukaj po nazwie"
            onChange={(e) => debouncedResults(e.target.value)}
          />
        </Box>

        {haveDeleted.length ? (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mr: "16px" }}>
            <Button
              variant="contained"
              onClick={() => setShowDeleted((prev) => !prev)}
            >
              {!showDeleted ? "Pokaż usunięte" : "Schowaj usunięte"}
            </Button>
          </Box>
        ) : null}

        {!editBlockedPartially && haveSettled.length ? (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mr: "16px" }}>
            <Button
              variant="contained"
              onClick={() => setHideSettled((prev) => !prev)}
            >
              {hideSettled ? "Pokaż rozliczone" : "Schowaj rozliczone"}
            </Button>
          </Box>
        ) : null}

        {!editBlocked ? (
          <Box sx={{ display: "flex", justifyContent: "flex-end", mr: "16px" }}>
            <Button
              variant="contained"
              onClick={() => setModalOpen(true)}
              disabled={!currentSelected.length}
            >
              Rozlicz
            </Button>
          </Box>
        ) : null}
      </Box>

      <EditItems
        currentSelected={currentSelected}
        editModalOpen={modalOpen}
        getItems={getItems}
        setEditModalOpen={setModalOpen}
      />

      <Center>
        <div
          style={{ width: "100%" }} //@ts-ignore
          ref={tableRef}
        >
          {items.length ? (
            <TableContainer
              component={Paper}
              sx={{ mt: "90px", overflowX: "initial", position: "relative" }}
            >
              <Box
                sx={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: "-50px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    visibility: screenshotDisabled ? "visible" : "hidden",
                  }}
                >
                  data i czas zrobienia screenshota: {dateTimeValue}
                </Typography>
              </Box>
              <Table
                sx={{
                  minWidth: 1550,
                  "& .MuiTableCell-root": {
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                  },
                }}
                stickyHeader
              >
                <TableHead
                  sx={{
                    transform: "translateY(70px)",
                    zIndex: "1",
                    position: "relative",
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => {
                        setSortedBy("createdDate");
                        setDireciton((prev) =>
                          prev.createdDate === "asc"
                            ? { ...prev, createdDate: "desc" }
                            : { ...prev, createdDate: "asc" }
                        );
                      }}
                    >
                      Nazwa produktu
                      {sortedBy === "createdDate" ? (
                        <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                          {direction.createdDate === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Status
                    </TableCell>

                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Kwota do rozliczenia
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", cursor: "pointer" }}
                      onClick={() => {
                        setSortedBy("settlementDate");
                        setDireciton((prev) =>
                          prev.settlementDate === "asc"
                            ? { ...prev, settlementDate: "desc" }
                            : { ...prev, settlementDate: "asc" }
                        );
                      }}
                    >
                      Data rozliczenia
                      {sortedBy === "settlementDate" ? (
                        <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                          {direction.settlementDate === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        maxWidth: "50px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSortedBy("settlementId");
                        setDireciton((prev) =>
                          prev.settlementId === "asc"
                            ? { ...prev, settlementId: "desc" }
                            : { ...prev, settlementId: "asc" }
                        );
                      }}
                    >
                      # Rozliczenia
                      {sortedBy === "settlementId" ? (
                        <Box sx={{ position: "absolute", top: 0, right: 0 }}>
                          {direction.settlementId === "asc" ? (
                            <ArrowDropUpIcon />
                          ) : (
                            <ArrowDropDownIcon />
                          )}
                        </Box>
                      ) : null}
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Uwagi
                    </TableCell>
                    {!editBlocked ? (
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        Akcja
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ height: "70px" }}>
                    <TableCell />
                  </TableRow>
                  {items
                    // @ts-ignore
                    .map((item) => {
                      if (!item.hasOwnProperty("elementId")) {
                        return;
                      }

                      if (showDeleted && !item.removed) {
                        return;
                      }

                      if (hideSettled) {
                        if (
                          item.settlementStatus === "rozliczono" &&
                          (item.status === "sprzedano" ||
                            item.status === "zwrot")
                        ) {
                          return;
                        }
                      }

                      if (!showDeleted && item.removed) {
                        return;
                      }

                      const cleringWojtek = Number(item.clearingValueWojtek);
                        
                      if (
                        !item.removed &&
                        item.settled &&
                        item.settlementStatus === "rozliczono"
                      ) {
                      } else if (
                        !item.removed &&
                        !item.settled &&
                        item.settlementStatus !== "nierozliczono"
                      ) {
                        summaryWojtek += cleringWojtek;
                      } else if (
                        !item.removed &&
                        !item.settled &&
                        item.settlementStatus === "nierozliczono"
                      ) {
                        summaryWojtek -= cleringWojtek;
                      } else if (
                        !item.removed &&
                        item.status === "zwrot" &&
                        item.settled &&
                        item.settlementStatus === "rozliczono"
                      ) {
                        summaryWojtek -= cleringWojtek;
                      }

                      const itemSelectedFound = currentSelected.find(
                        (e) => e.id === item.id
                      );
                      const isSelected = Boolean(itemSelectedFound);

                      const returned = item.status === "zwrot";

                      const removedCellStyles =
                        returned && !item.settled
                          ? {
                              textDecoration: "line-through",
                            }
                          : {};

                      return (
                        <TableRow
                          key={item.id}
                          sx={{
                            backgroundColor: isSelected
                              ? "#1471cd47"
                              : `${item.color}26`,
                            "&:hover": {
                              backgroundColor: isSelected
                                ? "#1976d252"
                                : "#f3f3f3",
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            onClick={() => handleOpenElem(item.elementId)}
                            sx={{
                              color: returned ? "red" : "inherit",
                              fontWeight: returned ? "bold" : "inherit",
                              maxWidth: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              position: "relative",
                              cursor: "pointer",
                              ...removedCellStyles,
                            }}
                          >
                            {item.productName}
                            {item.settled ? (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: "17px",
                                  right: "6px",
                                }}
                              >
                                <CheckCircleSharpIcon
                                  fontSize="small"
                                  sx={{ color: "green " }}
                                />
                              </Box>
                            ) : null}
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              color: returned
                                ? "red"
                                : item.status === "sprzedano"
                                ? "green"
                                : "inherit",
                              fontWeight: "bold",
                            }}
                          >
                            {item.status}
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              color: returned ? "red" : "inherit",
                              fontWeight: returned ? "bold" : "inherit",
                            }}
                          >
                            <Box sx={removedCellStyles}>
                              {item.settled && item.status === "zwrot" && "-"}
                              {cleringWojtek.toFixed(2)}zł
                            </Box>
                          </TableCell>

                          <TableCell align="right">
                            {item.settlementDate
                              ? dayjs(item.settlementDate).format("DD/MM/YYYY")
                              : "-"}{" "}
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              color: returned ? "red" : "inherit",
                              fontWeight: returned ? "bold" : "inherit",
                              maxWidth: "50px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              position: "relative",
                              cursor: "pointer",
                            }}
                          >
                            {item.settlementId}
                          </TableCell>

                          <TableCell
                            align="right"
                            sx={{
                              maxWidth: "400px",
                              minWidth: "400px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              textAlign: "left",
                            }}
                          >
                            {item.details}
                          </TableCell>
                          {!editBlocked ? (
                            <>
                              {!item.removed &&
                              item.settlementStatus !== "rozliczono" &&
                              item.status !== "zwrot" ? (
                                <TableCell align="right">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                    disabled={item.settled}
                                    color={"primary"}
                                    onClick={() => handleMulitSettlement(item)}
                                    sx={{ ml: "20px" }}
                                  >
                                    +
                                  </Button>
                                </TableCell>
                              ) : !item.removed &&
                                item.status === "zwrot" &&
                                !item.settled &&
                                item.settlementStatus === "nierozliczono" ? (
                                <TableCell align="right">
                                  <Button
                                    size="small"
                                    variant="contained"
                                    type="submit"
                                    color={"error"}
                                    onClick={() => handleMulitSettlement(item)}
                                    sx={{ ml: "20px" }}
                                  >
                                    +
                                  </Button>
                                </TableCell>
                              ) : (
                                <TableCell align="right"></TableCell>
                              )}
                            </>
                          ) : (
                            <TableCell align="right"></TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  boxSizing: "border-box",
                  width: "auto",
                  margin: "20px",
                }}
              >
                <Box sx={{ fontWeight: "bold" }}>Podsumowanie</Box>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  Wojtek suma:{" "}
                  <Box
                    sx={{
                      fontWeight: "bold",
                      marginLeft: "10px",
                      minWidth: "150px",
                      textAlign: "end",
                    }}
                  >
                    {summaryWojtek.toFixed(2)}zł
                  </Box>
                </Box>
              </Box>
              <Box sx={{ height: "50px" }} />
            </TableContainer>
          ) : (
            <Box sx={{ my: "40px" }}>Brak danych</Box>
          )}
        </div>
      </Center>
    </Container>
  );
};

export default RozliczeniaKomis;
